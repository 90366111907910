import React, {useState, useEffect} from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

Array.prototype.except = function(val) {
    return this.filter(function(x) { return x !== val; });
};

function ShopItemDescription({title, description, imagesData}) {
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const [imagesIndexes, setImagesIndexes] = useState([]);

    useEffect(() => {
        let startingArr = [...Array(imagesData.length).keys()];
        setImagesIndexes(startingArr);
    }, [mainImageIndex]);


    const handleImageClick = (imageIndex) => {
        setMainImageIndex(imageIndex)
    };

    return (
        <div className="about-area ptb--80 bg_color--1">
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-12">
                            <div className="thumbnail">
                                <GatsbyImage image={getImage(imagesData[mainImageIndex])} alt={title} className="img-fluid w-100" />
                            </div>

                            <div className="other-product-images-container">
                                { imagesIndexes.map(imageIndex => (
                                    <GatsbyImage
                                        image={getImage(imagesData[imageIndex])}
                                        alt={title}
                                        className={`product-gallery-image ${imageIndex === mainImageIndex && 'active'}`}
                                        onClick={() => handleImageClick(imageIndex)}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">{title}</h2>
                                    <p className="description">{description}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopItemDescription;
