import React from 'react';
import Header from "../components/headers/Header";
import ShopItemHeader from "../components/shop-items/ShopItemHeader";
import ShopItemDescription from "../components/shop-items/ShopItemDescription";
import CallToAction from "../components/layouts/CallToAction";
import Footer from "../components/layouts/Footer";
import NakedSEO from "../components/seo/NakedSeo";
import {graphql} from 'gatsby';
function MineProduct({data, pageContext: {title, description, slug, imageDirectory}}){

    return (
        <div>
            <NakedSEO title={`${title} - Trgovina in izdelki Rudnika Sitarjevec`} description={description}  />
            <Header/>
            <ShopItemHeader title="Trgovina in izdelki Rudnika Sitarjevec"/>
            <ShopItemDescription title={title} description={description} imagesData={data.images.nodes}/>
            <CallToAction/>
            <Footer />
        </div>
    );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
    query($imagesRegex: String!) {
        images: allFile(filter: {relativePath: {regex: $imagesRegex}}) {
            nodes {
                childImageSharp {
                    gatsbyImageData(
                        width: 800
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )

                    fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
`;

export default MineProduct;
