import React from 'react';
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function NakedSEO({ children, location, description, title, image }) {

    const defaultTitle = 'Rudnik Sitarjevec Litija';

    return (
        <Helmet titleTemplate={`%s - ${defaultTitle} `}>
            <html lang="sl" />
            <title>{title}</title>

            <meta http-equiv="cache-control" content="no-cache" />
            <meta http-equiv="expires" content="0" />
            <meta http-equiv="pragma" content="no-cache" />

            <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff"/>

            <meta name="viewport" content="width=device-width,initial-scale=1.0"/>
            <meta charSet="utf-8"/>
            <meta name="description" content={description}/>

            {/* Open graph */}
            {location && <meta property="og:url" content={location.href}/>}
            <meta property="og:image" content={image || '/logo.svg'}/>
            <meta property="og:title" content={title} key="ogtitle"/>
            <meta property="og:site_name" content={title}/>
            <meta property="og:description" content={description} key="ogdesc"/>
        </Helmet>
    )
};

NakedSEO.defaultProps = {
    lang: `sl`,
    meta: [],
    description: ``,
};

NakedSEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default NakedSEO;
